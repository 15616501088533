import { applySnapshot, getSnapshot, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  parkingDefaultModel,
  transformerDefaultModel,
  sense_4_20mADefaultModel,
  frameDefaultModel,
  userDefaultModel,
  transitDefaultModel,
  mceDefaultModel,
  roadsignDefaultModel,
  energymonitorDefaultModel,
  energy_efficiencyDefaultModel,
  loopsDefaultModel,
  gasDefaultModel,
  gatewayDefaultModel,
  alarmsDefaultModel,
  mapDefaultModel,
  freezerDefaultModel,
  lakeDefaultModel,
  weatherDefaultModel,
  residentialDefaultModel,
  cevtprDefaultModel,
  levelsensor2DefaultModel,
  TrackerDefaultModel
} from "models";

export const resetStore = (self: any) => {
  let initialState: any;
  return { afterCreate() { initialState = getSnapshot(self); }, resetStore() { applySnapshot(self, initialState); } };
};

export const RootStoreModel = types.model("RootStore").props({
  parkingAlarmsStore: parkingDefaultModel(),
  transformerAlarmsStore: transformerDefaultModel(),
  sense_4_20mAAlarmsStore: sense_4_20mADefaultModel(),
  usersStore: userDefaultModel(),
  transitStore: transitDefaultModel(),
  mcesAlarmStore: mceDefaultModel(),
  CEVTPTAlarmStore: cevtprDefaultModel(),
  LevelSensor2AlarmStore: levelsensor2DefaultModel(),
  trackerAlarmStore: TrackerDefaultModel(),
  roadsignAlarmStore: roadsignDefaultModel(),
  energymonitorAlarmStore: energymonitorDefaultModel(),
  energy_efficiencyAlarmStore: energy_efficiencyDefaultModel(),
  loopsAlarmStore: loopsDefaultModel(),
  gasAlarmStore: gasDefaultModel(),
  gatewayAlarmStore: gatewayDefaultModel(),
  alarmsStore: alarmsDefaultModel(),
  frameStore: frameDefaultModel(),
  mapStore: mapDefaultModel(),
  freezerAlarmStore: freezerDefaultModel(),
  lakeAlarmStore: lakeDefaultModel(),
  weatherAlarmStore: weatherDefaultModel(),
  residentialAlarmStore: residentialDefaultModel(),
});

export const rootStore = RootStoreModel.create();
export type RootStore = Instance<typeof RootStoreModel>;
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;