// External Modules
import { observer } from "mobx-react-lite";
import { HashRouter, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFireFlameSimple, faCar, faMoon, faSignsPost, faE, faCloud, fa3, fas, faSquareArrowUpRight, faArrowUp19, } from "@fortawesome/free-solid-svg-icons";
import persist from "mst-persist";
import React from "react";

// Internal Modules
import { rootStore, useStores } from "models";
import api from "services/api/api";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const Login = React.lazy(() => import("./views/pages/login/Login"));

const App = observer(() => {
  library.add(
    faFireFlameSimple,
    faCar,
    faMoon,
    faSignsPost,
    faE,
    faCloud,
    fa3,
    fas
  ); // Add icon component to refer to icons as a string in the Fontawesome component

  const { usersStore } = useStores();
  persist("iotinmotion", rootStore).then(() => {
    if (rootStore.usersStore.token && rootStore.usersStore.token !== "") {
      api.api.apisauce.headers.Authorization = `Bearer ${rootStore.usersStore.token}`;
    }
  });

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        {usersStore.token ? (
          <Route path="/" render={(props) => <DefaultLayout {...props} />} />
        ) : (
          <Route path="/" render={() => <Login />} />
        )}
      </React.Suspense>
    </HashRouter>
  );
});

export default App;
