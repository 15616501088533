import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api/api";

const Coordinates = types.model("coordinates").props({ coordinates: types.array(types.number) });

const AlarmsModel = types.model("Alarms").props({
  id: types.maybeNull(types.string),
  connected: types.maybeNull(types.boolean),
  connectedDate: types.maybeNull(types.string),
  location: types.maybeNull(Coordinates),
  group: types.maybeNull(types.string),
  areaContainer_m2: types.maybeNull(types.number),
  battery: types.maybeNull(types.number),
  heightColor: types.maybeNull(types.string),
  heightContainerMeter: types.maybeNull(types.number),
  heightContentPercentage: types.maybeNull(types.number),
  heightLimit1Porcentage: types.maybeNull(types.number),
  heightLimit2Porcentage: types.maybeNull(types.number),
  temperature: types.maybeNull(types.number),
});

const Location = types.model("Location").props({ lat: types.number, lng: types.number });

export const LevelSensor2ModelAlarm = types
  .model("levelsensor")
  .props({
    alarms: types.optional(types.array(AlarmsModel), []),
    locationSelected: types.maybeNull(Location),
    idSelected: types.maybeNull(types.string),
    isLoading: types.boolean,
    idLoading: types.maybeNull(types.string),
    center: Location,
  })
  .views((self) => ({
    filterValues(value: string | null) {
      if (value === null) return self.alarms;
      return self.alarms.filter(
        (alarm) =>
          alarm.id?.toLowerCase().includes(value.toLowerCase())
      );
    },
  }))
  .actions((self) => {
    function setAlarms(alarms: any) { self.alarms = alarms; }
    function setlocationSelected(cordinates: any) { self.locationSelected = cordinates; }
    function setId(id: any) { self.idSelected = self.idSelected === id ? null : id; }
    function setIsLoading(isLoading: boolean, id: string | null) { self.idLoading = id; self.isLoading = isLoading; }
    function setLoading(loading: any) { self.isLoading = loading; }
    function setCenter(coordinates: any) { self.center = coordinates; }
    return { setAlarms, setlocationSelected, setCenter, setIsLoading, setLoading, setId };
  })
  .actions((self) => ({
    getDevices: async () => {
      try {
        self.setLoading(true);
        const alarmsApi = api.levelsensor2;
        const response = await alarmsApi.getDevice();
        self.setAlarms(response?.devices);
        self.setCenter(response?.center);
        self.setLoading(false);
        self.setIsLoading(false, null);
      } catch (error) { console.log(error); }
    },
    sendAction: async (payload: any) => {
      try {
        const levelsensor2Api = api.levelsensor2;
        await levelsensor2Api.changeStatus(payload);
        self.setIsLoading(true, payload.serial);
      } catch (error) { console.log(error); }
    },
  }));

type LevelSensor2ModelType = Instance<typeof LevelSensor2ModelAlarm>;
export type LevelSensor2 = LevelSensor2ModelType;
type LevelSensor2SnapshotType = SnapshotOut<typeof LevelSensor2ModelAlarm>;
export type LevelSensor2Snapshot = LevelSensor2SnapshotType;
export const levelsensor2DefaultModel = () => types.optional(LevelSensor2ModelAlarm, { alarms: [], locationSelected: null, idSelected: null, isLoading: false, idLoading: null, center: { lat: 0, lng: 0 } });